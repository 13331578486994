import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/constants/storage-key';
import { isBrowser } from '@trustless-computer/dapp-core';

export const getAccessToken = (): string | null => {
  if (!isBrowser()) {
    return null;
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN) as string;
  return accessToken;
};

export const clearAuthStorage = (): void => {
  if (!isBrowser()) {
    return;
  }

  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const setAccessToken = (accessToken: string, refreshToken: string): void => {
  if (!isBrowser()) {
    return;
  }

  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};
