import { BITCOIN_PHOTO_SECRET, BITCOIN_PHOTO_GENERATING } from '@/constants/storage-key';
import sessionStorage from '@/utils/sessionstorage';

class SecretStorage {
  private getStorageKey = (evmAddress: string) => {
    return `${BITCOIN_PHOTO_SECRET}_${evmAddress.toLowerCase()}`;
  };

  getUserIsGeneratingSecret = (): string | null => {
    return sessionStorage.get<string | null>(BITCOIN_PHOTO_GENERATING);
  };

  setUserIsGeneratingSecret = (isProgress: boolean) => {
    return sessionStorage.set(BITCOIN_PHOTO_GENERATING, isProgress);
  };

  removeUserIsGeneratingSecret = () => {
    return sessionStorage.remove(BITCOIN_PHOTO_GENERATING);
  };

  getUserSecret = (evmAddress: string): string | null => {
    const key = this.getStorageKey(evmAddress);
    return sessionStorage.get<string | null>(key);
  };

  setUserSecret = (evmAddress: string, secret: string) => {
    const key = this.getStorageKey(evmAddress);
    return sessionStorage.set(key, secret);
  };

  removeUserSecret = (evmAddress: string) => {
    const key = this.getStorageKey(evmAddress);
    return sessionStorage.remove(key);
  };
}

const instance = new SecretStorage();

export default instance;
